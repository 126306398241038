/* 
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* 
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-controls .owl-nav .owl-prev,
.owl-carousel .owl-controls .owl-nav .owl-next,
.owl-carousel .owl-controls .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
}
.owl-carousel .owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/* 
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item img {
  transform-style: preserve-3d;
}

/* 
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("../images/owl.video.play.html") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -moz-transition: scale 100ms ease;
  -ms-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  -webkit-transition: scale(1.3, 1.3);
  -moz-transition: scale(1.3, 1.3);
  -ms-transition: scale(1.3, 1.3);
  -o-transition: scale(1.3, 1.3);
  transition: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -moz-transition: opacity 400ms ease;
  -ms-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
}

/* ----------------------------------------------------------- */

/* 
 Home slide top
*/
#slide-top .owl-controls {
    position: absolute;
    top: 50%;
    width: 100%;
}
/*
#slide-top .owl-controls .owl-prev{
    position: relative;
    float: left;
    width: 17px;
    height: 30px;
    margin-left: 30px;
    background-image:url(../images/h-slide1/fr-sx.png);
    background-repeat: no-repeat;
    content: "";
}
#slide-top .owl-controls .owl-next{
    float: right;
    width: 17px;
    height: 30px;
    text-align: right;
    margin-right: 30px;
    background-image:url(../images/h-slide1/fr-dx.png);
    background-repeat: no-repeat;
}
*/

/* 
 Carosello sotto
*/
.sotto .item{
    box-sizing: border-box;
}
.sotto .owl-controls {
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 0;
    width: 100%;
}
.sotto .owl-controls .owl-prev{
    position: relative;
    float: left;
    color: #c1c1c1;
}
.sotto .owl-controls .owl-prev:before{
    content: "\f104";
    font-family: 'icomoon';
    speak: none;
    
    position: absolute;
    top: 0;
    left: -45px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 60px;
}
.sotto .owl-controls .owl-next{
    position: relative;
    float: right;
    color: #c1c1c1;
}
.sotto .owl-controls .owl-next:before{
    content: "\f105";
    font-family: 'icomoon';
    speak: none;
    
    position: absolute;
    top: 0;
    right: -25px;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 60px;
}
@media(max-width:1024px){
    .sotto .owl-controls {
        left: auto;
        right: 20px;
        top: -60px;
        width: 30px;
        margin-top: 0;
    }
    .sotto .owl-controls .owl-prev::before {
        top: 0px;
        left: 0px;
    }
    .sotto .owl-controls .owl-next::before {
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }
}
@media(max-width:767px){
    .sotto .owl-controls {
        width: 40px;
        top: -55px;
        right: 15px;
        left: auto;
    }
    .sotto .owl-controls .owl-prev:before{
        left: 0;
    }
    .sotto .owl-controls .owl-next:before{
        right: 0;
    }
}

/* 
 Isola Bella - Giardini
*/
.giardini-slide .cont-descrizione {
    position: absolute;
    bottom: 10%;
    width: 100%;
    text-align: center;
    z-index: 1;
}
.giardini-slide .cont-descrizione .int{
    display: inline-block;
    width: 1170px;
    padding: 0 15px;
    text-align: left;
    box-sizing: border-box;
}
.giardini-slide .cont-descrizione .int .descrizione{
    display: inline-block;
    width: 50%;
    border-top: 10px solid #659599; 
    background-color: rgba(0,0,0,0.8);
    padding: 30px;
    text-align: left;
    color: #FFF;
    box-sizing: border-box;
}
.giardini-slide .owl-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    opacity: 0;
    transition: opacity;
    transition-duration: 0.3s;
}
.giardini-slide:hover .owl-controls {
    opacity: 1;
}

.giardini-slide .owl-controls .owl-prev{
    position: relative;
    float: left;
}
.giardini-slide .owl-controls .owl-prev:before{
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 21px;
    height: 32px;
    background-image: url(../images/fr-sx.png);
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    border-radius:4px;
}
.giardini-slide .owl-controls .owl-next{
    position: relative;
    float: right;
}
.giardini-slide .owl-controls .owl-next:before{
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    width: 21px;
    height: 32px;
    background-image: url(../images/fr-dx.png);
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    border-radius:4px;
}

@media(max-width:1199px){
    .giardini-slide .cont-descrizione .int{
        width: 970px;
    }
}
@media(max-width:1024px){
    .giardini-slide .owl-controls {
        opacity: 1;
    }
}
@media(max-width:991px){
    .giardini-slide .owl-controls {
        opacity: 1;
    }
    .giardini-slide .cont-descrizione .int{
        width: 750px;
    }
}

@media(max-width:767px){
    .giardini-slide .owl-controls {
        opacity: 1;
    }
    /*
    .giardini-slide .owl-controls {
        position: absolute;
        top: -40px;
        width: 60px;
        right: 15px;
    }
    .giardini-slide .owl-controls .owl-prev:before{
        left: 0;
    }
    .giardini-slide .owl-controls .owl-next:before{
        right: 0;
    }
    .giardini-slide .cont-descrizione {
        bottom: 0;
    }
    .giardini-slide .cont-descrizione .int{
        width: 100%;
        padding: 0;
    }
    .giardini-slide .cont-descrizione .int .descrizione{
        width: 100%;
        padding: 5px 15px;
    }
    */
}


/* 
 Isola Bella - Palazzo
*/
.palazzo-slide .descrizione{
    position: relative;
    float: left;
    width: 50%;
    box-sizing: border-box;
    padding-right: 30px;
    padding-left: 15px;
}
.palazzo-slide .immagine{
    position: relative;
    float: right;
    width: 50%;
    box-sizing: border-box;
    padding-right: 15px;
}
.palazzo-slide .owl-controls {
    position: absolute;
    bottom: 20%;
    width: 90px;
}

.palazzo-slide .owl-controls .owl-prev{
    position: relative;
    float: left;
}
.palazzo-slide .owl-controls .owl-prev:before{
    content: "\f104";
    font-family: 'icomoon';
    speak: none;
    
    position: absolute;
    top: 0;
    left: 0;
    font-size: 60px;
    line-height: 60px;
    color: #666666;
}
.palazzo-slide .owl-controls .owl-next{
    position: relative;
    float: right;
}
.palazzo-slide .owl-controls .owl-next:before{
    content: "\f105";
    font-family: 'icomoon';
    speak: none;
    
    position: absolute;
    top: 0;
    right: 0;
    font-size: 60px;
    line-height: 60px;
    color: #666666;
}

@media(max-width:991px){
    .palazzo-slide .owl-controls .owl-prev:before{
        font-size: 40px;
    }
    .palazzo-slide .owl-controls .owl-next:before{
        font-size: 40px;
    }
}
@media(max-width:767px){
    .palazzo-slide .owl-controls {
        position: absolute;
        top: -50px;
        right: 10px;
        bottom: auto;
        width: 50px;
    }
    .palazzo-slide .descrizione{
        width: 100%;
        padding: 0 10px 10px;
    }
    .palazzo-slide .immagine{
        width: 100%;
        padding: 0 10px;
    }
}

/* Storia */
#sync1 .item{
    background: #0c83e7;
    padding: 80px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
}
#sync2 .item{
    background: #C9C9C9;
    padding: 10px 0px;
    margin: 5px;
    color: #FFF;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
}
#sync2 .item h1{
  font-size: 18px;
}
#sync2 .synced .item{
  background: #0c83e7;
}

/* 
 Rocca di Angera - Museo
*/
.museo-slide .cont-descrizione {
    position: absolute;
    bottom: 10%;
    width: 100%;
    text-align: center;
    z-index: 1;
}
.museo-slide .cont-descrizione .int{
    display: inline-block;
    width: 1170px;
    padding: 0 15px;
    text-align: left;
    box-sizing: border-box;
}
.museo-slide .cont-descrizione .int .descrizione{
    display: inline-block;
    width: 50%;
    border-top: 10px solid #659599; 
    background-color: rgba(0,0,0,0.8);
    padding: 30px;
    text-align: left;
    color: #FFF;
    box-sizing: border-box;
}
.museo-slide .owl-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    opacity: 0;
    
    transition: opacity;
    transition-duration: 0.3s;
}
.museo-slide:hover .owl-controls {
    opacity: 1;
}
.museo-slide .owl-controls .owl-prev{
    position: relative;
    float: left;
}
.museo-slide .owl-controls .owl-prev:before{
    content: "";
    position: absolute;
    top: 0;
    left: 15px;
    width: 21px;
    height: 32px;
    background-image: url(../images/fr-sx.png);
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    border-radius:4px;
}
.museo-slide .owl-controls .owl-next{
    position: relative;
    float: right;
}
.museo-slide .owl-controls .owl-next:before{
    content: "";
    position: absolute;
    top: 0;
    right: 15px;
    width: 21px;
    height: 32px;
    background-image: url(../images/fr-dx.png);
    background-repeat: no-repeat;
    background-color: rgba(0,0,0,0.5);
    background-position: center;
    border-radius:4px;
}

@media(max-width:1199px){
    .museo-slide .cont-descrizione .int{
        width: 970px;
    }
}
@media(max-width:991px){
    .museo-slide .cont-descrizione .int{
        width: 750px;
    }
}
@media(max-width:767px){
    .museo-slide .owl-controls {
        opacity: 1;
    }
    .museo-slide .cont-descrizione {
        bottom: 0;
    }
    .museo-slide .cont-descrizione .int{
        width: 100%;
        padding: 0;
    }
    .museo-slide .cont-descrizione .int .descrizione{
        width: 100%;
        padding: 5px 15px;
    }
}






