#nav
{
    /* container */
}
#nav > a
{
    display: none;
}
#nav li
{
    position: relative;
}

/* first level */

#nav > ul
{
    display: inline-block;
    list-style-type: none;
    padding-left: 0;
    margin-bottom: -6px;
}
#nav > ul > li
{
    position: relative;
    float: left;
}
#nav > ul > li:hover
{
    background-color: #000;
}
#nav > ul > li:last-child{
    padding-right: 0;
}
#nav > ul > li > a
{
    display: table-cell;
    position: relative;
    width: 100%;
    height: 86px;
    box-sizing: border-box;
    color: #000;
    font-family: 'Oswald', sans-serif;
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    text-transform: uppercase;
    vertical-align: middle;
    padding: 0 10px;

    transition: background, color, height;
    transition-duration: 0.4s;
}
#nav > ul > li > a > span
{
    font-family: Georgia, Serif;
    font-size: 14px;
    line-height: 25px;
    font-weight: 300;
}
#nav > ul > li:hover a
{
    color: #FFF;
}
#nav > ul > li > a:hover
{
    background-color: #000;
    color: #FFF;
}
#nav > ul > li.attivo > a
{
    background-color: #000;
    color: #FFF;
}
#nav > ul > li > a > i
{
    display: inline-block;
    float: right;
    margin: 15px 10px 0 0;
}
#nav .apri-sub
{
    display: none;
}
/* second level */

#nav li ul
{
    display: none;
    position: absolute;
    list-style-type: none;
    top: 100%;
    min-width: 170px;
    box-sizing: border-box;
    background-color: #000;
    margin: 0;
    padding: 0;
    z-index: 2;
}
#nav li:nth-child(8n) ul
{
    right: 0;
}
#nav li:hover ul
{
    display: block;
}
#nav li li:first-child a
{
    border-top: 0;
}
#nav li li:last-child a
{
    border-bottom: 0;
}
#nav li li a
{
    display: block;
    padding: 10px 15px;
    color: #FFF;
    text-align: left;
    font-size: 16px;
    text-transform: none;
    border-bottom: 1px solid #1a1a1a;
    transition: background, color;
    transition-duration: 0.3s;
}
#nav li li a:hover
{
    background-color: #1C1C1C;
}
#nav li li.attivo a
{
    background-color: #1C1C1C;
}

/* third livel */
#nav li li > ul
{
    display: none !important;
}

@media ( max-width: 1199px )
{

    #nav > ul > li > a
    {
        font-size: 16px;
        padding: 0 10px;
    }
    #nav > ul > li > a > span
    {
        font-size: 12px;
    }
    #nav li li a
    {
        font-size: 12px;
    }
}
@media ( min-width: 768px ) and ( max-width: 991px )
{
    header .cont-servizio ul.servizio li {
        padding-right: 0;
    }
    header .cont-servizio ul.servizio li:first-child {
        display: block;
        float: none;
        padding-right: 0;
    }
    header .cont-servizio ul.servizio li a{
        padding-right: 0;
    }
    header .cont-servizio ul.servizio li > p {
        padding-right: 8px;
        padding-left: 5px;
    }

    #nav > ul > li > a
    {
        font-size: 14px;
        padding: 0 5px;
    }
    #nav > ul > li > a > span
    {
        font-size: 11px;
    }
}
@media ( max-width: 767px )
{
    #nav
    {
        position: relative;
        width: 100%;
    }
    #nav > a
    {
        right: 5px;
        width: 44px;
        height: 55px;
        text-align: left;
        text-indent: -9999px;
        background-color: #FFF;
        position: relative;
        float: right;
    }
    #nav > a::before {
        position: absolute;
        border: 2px solid #000;
        top: 35%;
        left: 25%;
        right: 25%;
        content: "";
    }
    #nav > a::after {
        position: absolute;
        border: 2px solid #000;
        top: 64%;
        left: 25%;
        right: 25%;
        content: "";
    }
    #nav > a .riga
    {
        position: absolute;
        border: 2px solid #000;
        top: 50%;
        left: 25%;
        right: 25%;
    }
    #nav:not( :target ) > a:first-of-type,
    #nav:target > a:last-of-type
    {
        display: inline-block;
    }

    /* first level */

    #nav > ul
    {
        height: auto;
        display: none !important;
        position: relative;
        left: 0;
        right: 0;
    }
    #nav > ul.attivo-mob
    {
        display: block !important;
        position: fixed;
        top: 125px;
        bottom: 0;
        overflow: auto;
        /* border-top: 2px solid #f00; */
    }
    #nav:target > ul
    {
        display: block;
    }
    #nav > ul > li
    {
        width: 100%;
        float: none;
        border-bottom: 1px solid #dedede;
        padding: 0;
        background-color: #f5f5f5;
    }
    #nav > ul > li:hover
    {
        background-color: #f5f5f5;
    }
    #nav > ul > li > a
    {
        display: block;
        padding-right: 10px;
        padding-left: 20px;
        padding-top: 22px;
        padding-bottom: 22px;
        text-align: left;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 300;
        color: #000;
        height: auto;
        min-height: auto;
        text-transform: uppercase;
    }
    #nav > ul > li:hover > a
    {
        background-color: #EBEBEB;
        color: #000;
    }
    #nav > ul > li.attivo a
    {
        background-color: #ebebeb;
        color: #000;
    }
    #nav > ul > li > a > span
    {
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 300;
    }
    #nav > ul > li a:hover
    {
        background-color: #ebebeb;
        color: #000;
    }

    #nav > ul > li > a::after
    {
        height: 1px;
        background-color: #c4c4c4;
        bottom: -1px;
    }
    #nav > ul > li.attivo > a::after
    {
        height: 1px;
        background-color: #c4c4c4;
        bottom: -1px;
    }
    #nav .apri-sub
    {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        height: 69px;
        width: 30%;
        /* background-color: bisque; */
        z-index: 99;
    }
    #nav .apri-sub span
    {
        position: absolute;
        top: 22px;
        right: 15px;
        font-size: 20px;
        color: #000;
        transition: transform;
        transition-duration: 0.5s;
    }
    #nav .apri-sub.attivo span
    {
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
    }
    #nav li::after
    {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        z-index: 1;
    }
    /* second level */

    #nav li ul
    {
        position: static;
        top: 44px;
        width: 100%;
        background-color: #f7f7f7;
        border-top: 0;
    }
    #nav li ul.attivo
    {
        display: block !important;
    }
    #nav li:hover ul
    {
        display: none;
    }
    #nav li ul a
    {
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        font-weight: 300;
        color: #000 !important;
        text-transform: none;
        border-bottom: 1px solid #dedede;
        padding-left: 40px;
        padding-top: 22px;
        padding-bottom: 22px;
    }
    #nav li ul a:hover
    {
        background-color: #ebebeb;
    }
    #nav li ul a::after
    {
        content: "";
        position: absolute;
        left: 20px;
        top: 30px;
        width: 9px;
        height: 9px;
        border: 1px solid #000;
        -webkit-border-radius: 100px 100px 100px 100px;
        border-radius: 100px 100px 100px 100px;
    }
    #nav li ul a::before
    {
        display: none;
    }
    #nav li li:first-child > a
    {
        border-top: 1px solid #e0e0e0;
    }
    #nav li li.attivo > a
    {
        background-color: #ebebeb;
    }
    /* third level */
    #nav li li li
    {
        position: relative;
    }
    #nav li li li a
    {
        font-size: 14px;
        color: #026A8D;
        line-height: 45px;
        padding-left: 40px;
    }
    #nav li li li a::before
    {
        left: 30px;
    }

}

body.fixed header #nav > ul > li > a
{
    height: 65px;
}